import { AnyAction } from 'redux';
import { Opportunity } from '../../../types/content';
import {
  GLOBAL_MODAL_CLOSE_STATUS,
  GLOBAL_MODAL_CONFIGURATION,
  TOGGLE_APPLY_MODAL,
  TOGGLE_GLOBAL_MODAL
} from '../actions/types';

type Action = {
  text: string;
  appearance: string;
  action: 'close' | 'link';
  url?: string;
};

export type ModalConfig = {
  type?: 'dashboard-onboarding' | 'apply';
  icon?: string;
  actions?: Action[];
  isOverlay?: boolean;
  minheightContent?: string;
  fullScreen?: boolean;
  minWidthContent?: string;
  dialogClasses?: string;
  contentClasses?: string;
};

export type GlobalModalState = {
  isOpen: boolean;
  isCloseDisabled: boolean;
  modalConfig: ModalConfig | undefined;
  applyModalStatus: boolean;
  applyOpportunity?: Opportunity;
};

const INITIAL_STATE: GlobalModalState = {
  isOpen: false,
  isCloseDisabled: false,
  modalConfig: undefined,
  applyModalStatus: false,
  applyOpportunity: undefined
};

const globalModalReducer = (state = INITIAL_STATE, action: AnyAction): GlobalModalState => {
  switch (action.type) {
    case TOGGLE_GLOBAL_MODAL:
      return {
        ...state,
        isOpen: state.modalConfig ? action.payload : false,
        modalConfig: action.payload ? state.modalConfig : undefined
      };
    case GLOBAL_MODAL_CONFIGURATION:
      return { ...state, modalConfig: { ...state.modalConfig, ...action.payload } };
    case GLOBAL_MODAL_CLOSE_STATUS:
      return { ...state, isCloseDisabled: action.payload };
    case TOGGLE_APPLY_MODAL:
      return {
        ...state,
        applyModalStatus: action.payload.status,
        applyOpportunity: action.payload.opportunity,
        modalConfig: { ...state.modalConfig, ...action.payload }
      };
    default:
      return state;
  }
};

export default globalModalReducer;
