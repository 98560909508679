import { Button } from '@/components/ui/Button';
import { cn } from '@/lib/helpers/utils';
import {
  NotificationToast,
  NotificationToastConfig,
  NotificationToastContentSerialized,
  NotificationToastContext
} from '@/types/notification-toast';
import { XMarkIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer, ToastOptions } from 'react-toastify';
import { RootState } from '../../../app/redux/configureStore';
import { removeNotificationToast } from '../../../app/redux/features/notifications-toast';
import AttributionNotificationToast from '../../notifications/notification-toast/AttributionNotificationToast';

const NotificationToastSerializedContentComponent = {
  ['AttributionNotificationToast']: AttributionNotificationToast
};

const NotificationToastContent: React.FC<{
  title?: string;
  message?: string;
  type: string;
  config?: NotificationToastConfig;
  context?: NotificationToastContext;
}> = ({ title, message, type, config, context }) => {
  let SerializedContentComponent: any;
  if ((context?.content as any)?.component) {
    SerializedContentComponent = (NotificationToastSerializedContentComponent as any)[
      (context?.content as NotificationToastContentSerialized).component
    ];
  }

  return (
    <div className={cn('flex items-start space-x-3 flex-grow')}>
      <div className="flex-1 space-y-2">
        {title && <h3 className="font-semibold text-base leading-5">{title}</h3>}
        {message && <p className="text-sm">{message}</p>}
        {context?.content &&
          (SerializedContentComponent ? (
            <SerializedContentComponent {...(context?.content as NotificationToastContentSerialized).args} />
          ) : typeof context?.content === 'string' ? (
            <span dangerouslySetInnerHTML={{ __html: context?.content }}></span>
          ) : (
            (context?.content as React.ReactNode)
          ))}
      </div>
    </div>
  );
};

const notificationToastContextRegistry = new Map<string, NotificationToastContext>();

export const registerNotificationToastContext = (key: string, context: NotificationToastContext) => {
  notificationToastContextRegistry.set(key, context);
};

const defaultToastOptions: ToastOptions = {
  position: 'bottom-right',
  theme: 'dark',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: false,
  pauseOnHover: true
};

export const NotificationToastProvider: React.FC = () => {
  const notificationToasts: NotificationToast[] = useSelector(
    (state: RootState) => state.notificationToasts.notificationToasts
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    notificationToasts.forEach((notificationToast) => {
      if (!toast.isActive(notificationToast.id)) {
        const context = notificationToast.contentKey
          ? notificationToastContextRegistry.get(notificationToast.contentKey)
          : undefined;
        notificationToast.contentKey && notificationToastContextRegistry.delete(notificationToast.contentKey);

        if (!notificationToast?.title && !notificationToast?.message && !context) return false;

        toast(
          <NotificationToastContent
            title={notificationToast.title}
            message={notificationToast.message}
            type={notificationToast.type}
            config={notificationToast.config}
            context={context}
          />,
          {
            ...defaultToastOptions,
            ...notificationToast.config,
            toastId: notificationToast.id,
            type: notificationToast.type as any,
            closeButton: () => {
              return (
                <Button
                  icon
                  size="xs"
                  variant="bare"
                  appearance="background"
                  className="close -mr-0.5 bg-background/20 hover:bg-background/40 self-start"
                  onClick={(event) => {
                    toast.dismiss(notificationToast.id);
                    dispatch(removeNotificationToast(notificationToast.id));
                    context?.onClose && context.onClose();
                    event.stopPropagation();
                  }}
                >
                  <XMarkIcon className="w-4 text-current" />
                </Button>
              );
            },
            onClose: () => {
              dispatch(removeNotificationToast(notificationToast.id));
              context?.onDismiss && context.onDismiss();
            }
          }
        );
      }
    });
  }, [notificationToasts, dispatch]);

  return <ToastContainer {...defaultToastOptions} />;
};
